import { environment } from '../../../environments/environment';
import config from '../../../../config/config.json';
import { CacheLookupPolicy } from '@azure/msal-browser';

export const Constants = {
  AZURE: {
    CLIENT_ID: config.env === "prod" ? '@AZURE_CLIENT_ID@': environment.CLIENT_ID,
    TENANT_ID: config.env === "prod" ? '@AZURE_TENANT_ID@' : environment.TENANT_ID,
    URL_REDIRECT: config.env === "prod" ? '@URL_REDIRECT@' : environment.URL_REDIRECT,
  },
  EVENT_TYPE: {
    CFL: 'cfl',
    SOCIETY: 'society'
  },
  MICROSOFTGRAPH: {
    URL: 'https://graph.microsoft.com/v1.0/',
  },
  SCOPES: {
    scopes: ['User.Read'],
    cacheLookupPolicy: CacheLookupPolicy.AccessTokenAndRefreshToken,
  },
  SESSION_STORAGE: {
    EVENT: {
      DATE_SORT: 'event_date_sort',
      FILTER_TYPE: 'event_filter_type',
      NAME_SORT: 'event_name_sort',
      PAGE_SIZE: 'event_page_size',
      SEARCH_TITLE: 'search_title',
      SELECTED_SORT: 'event_selected_sort',
    },
    CALENDAR : 'calendar_holidays'
  }
};
