import { MsalGuardConfiguration } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { azure } from 'config/azure.environment';
import { Constants } from 'src/app/shared/constant/app.constants';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;


export function MSALInstanceFactory() {
  return new PublicClientApplication({
    auth: {
      clientId: azure.azure.clientId,
      authority: azure.azure.authority,
      redirectUri: azure.azure.redirectUriLocal,
      navigateToLoginRequestUrl: true 
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage, // This configures where your cache will be stored
      storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
    },
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: Constants.SCOPES,
    loginFailedRoute: '',
  };
}
