import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../core/services/access/auth.service';

@Component({
  selector: 'app-navbar-mobile',
  templateUrl: './navbar-mobile.component.html',
  styleUrls: ['./navbar-mobile.component.scss']
})
export class NavbarMobileComponent implements OnInit {
  name: string;
  defaultImg = 'IT';
  imageUrl: any;
  isAdmin: boolean;

  constructor(private readonly authService: AuthService) { }

  async ngOnInit(): Promise<void> {

    const splitName: string[] = this.authService.getDisplayName().split(' ');
    if (splitName.length >= 2) {
      this.name = `${splitName[0].charAt(0)}${splitName[1].charAt(0)}`;
    }
    this.readProfilePicture();
    this.isAdmin = this.authService.isAdmin();
  }

  readProfilePicture(): void {
    this.authService.getUserPicture()
    .then( blob => {
      this.readFile(blob)
      .then(result => this.imageUrl = result)
    })
    .catch(() => {});
  }

  /** Decode profile picture blob to base64 file */
  readFile(file){
    return new Promise((resolve, reject) => {
      var fr = new FileReader();  
      fr.onload = () => {
        resolve(fr.result)
      };
      fr.onerror = reject;
      fr.readAsDataURL(file);
    });
  }

  onLogout(): void {
    this.authService.logout();
  }
}
