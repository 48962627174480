import {NgModule} from '@angular/core';
import {
    NbAccordionModule,
    NbAutocompleteModule,
    NbButtonModule,
    NbCalendarModule,
    NbCalendarRangeModule,
    NbCardModule,
    NbCheckboxModule,
    NbContextMenuModule,
    NbFormFieldModule,
    NbIconModule,
    NbInputModule,
    NbLayoutModule,
    NbListModule,
    NbSelectModule,
    NbSpinnerModule,
    NbStepperModule,
    NbTabsetModule,
    NbToggleModule,
    NbTooltipModule
} from '@nebular/theme';
import {NbEvaIconsModule} from '@nebular/eva-icons';

@NgModule({
    declarations: [],

    imports: [
        NbLayoutModule,
        NbEvaIconsModule,
        NbButtonModule,
        NbCardModule,
        NbToggleModule,
        NbCalendarModule,
        NbTabsetModule,
        NbCalendarRangeModule,
        NbContextMenuModule,
        NbTooltipModule,
        NbSelectModule,
        NbCheckboxModule,
    ],

    exports: [
        NbLayoutModule,
        NbEvaIconsModule,
        NbButtonModule,
        NbCardModule,
        NbToggleModule,
        NbCalendarModule,
        NbListModule,
        NbIconModule,
        NbEvaIconsModule,
        NbStepperModule,
        NbInputModule,
        NbCalendarRangeModule,
        NbSpinnerModule,
        NbAutocompleteModule,
        NbCalendarRangeModule,
        NbContextMenuModule,
        NbTooltipModule,
        NbAccordionModule,
        NbSelectModule,
        NbCheckboxModule,
        NbFormFieldModule,
        NbTabsetModule,
    ]
})

export class NebularModule {}
