import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'firstWord'
})
export class FirstWordPipe implements PipeTransform {

    transform(value: string): string {
        let transformedValue = '';
        if (value) {
            transformedValue = value.split(' ')[0];
        }
        return transformedValue;
    }

}
