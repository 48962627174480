import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {environment} from '../../../environments/environment';
import {fromEvent} from 'rxjs';

@Directive({
  selector: '[appMobile]'
})
export class MobileDirective implements OnInit {
  private _mobile: boolean;
  private _windowType: string;
  private _onScreen = false;

  constructor(
              private readonly templateRef: TemplateRef<any>,
              private readonly viewContainer: ViewContainerRef) {
    this._mobile = window.screen.width  < environment.breakpoints.extraLarge;
  }

  @Input()
  set appMobile(value: string) {
    this._windowType = value;
  }

  ngOnInit() {
    this._mobile = window.screen.width  < environment.breakpoints.extraLarge;
    this._updateView();
    fromEvent(window, 'resize')
        .subscribe((event: any) => {
          const newMobile = event.target.screen.width < environment.breakpoints.extraLarge;
          if (!this._onScreen) {
            this._mobile = newMobile;
            this._updateView();
          } else {
            if (newMobile !== this._mobile) {
              this.viewContainer.clear();
              this._onScreen = false;
            }
          }
    });
  }

  private _updateView(): void {
    if ((this._mobile && this._windowType === 'mobile')
        || (!this._mobile && this._windowType === 'browser')) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this._onScreen = true;
    } else {
      this.viewContainer.clear();
      this._onScreen = false;
    }
  }
}
