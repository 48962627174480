<nb-card class="create-event-card card-shadow" [class.select-cat]="isFixedWidth">
    <nb-card-header class="dialog-header">
        <h3>{{title}}</h3>
    </nb-card-header>
    <nb-card-body class="create-event-card-body">
        <!-- First page -->
        <div class="create-choices" *ngIf="pageNumber === 0">
            <div class="create-choice" (click)="onCatalogChoice()">
                <div class="create-choice-header">
                    <h5 class="create-choice-title">Créer un événement à partir d'un modèle existant</h5>
                    <nb-icon class="nb-icon" icon="chevron-right-outline"></nb-icon>
                </div>
                <p>Choisir un événement dans le catalogue comme base pour votre nouvel événement.</p>
            </div>
            <div class="create-choice" (click)="onClose()" [routerLink]="'events/create'">
                <div class="create-choice-header">
                    <h5 class="create-choice-title">Créer un nouvel événement</h5>
                    <nb-icon class="nb-icon" icon="chevron-right-outline"></nb-icon>
                </div>
                <p>Créer un nouvel événement de zéro.</p>
            </div>
        </div>
        <!-- Second page -->
        <div class="container-fluid" *ngIf="pageNumber === 1">
            <div class="row">
                <div class="col-6">
                    <label for="search-cat-title-mobile">Nom de la catégorie</label>
                    <input nbInput
                           class="mb-3"
                           name="search-cat-title"
                           fullWidth
                           id="search-cat-title-mobile"
                           type="search"
                           placeholder="Commencer à taper le nom de la catégorie..."
                           [(ngModel)]="searchCategory"
                           (ngModelChange)="applySearchCatString()"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12 list-col">
                    <app-loading *ngIf="isLoadingCategory; else showCategories"></app-loading>
                    <ng-template #showCategories>
                        <div class="list-container">
                            <div class="col-xl-2 list-col" *ngFor="let category of categories">
                                <div class="list-box card-shadow pointer"
                                     (click)="onClickCategory(category)">
                            <span class="list-title" nbTooltip="{{category.name}}" >
                                {{category.name}}
                            </span>
                                    <div class="flex-aligned mt-2 mb-3">
                                        <div [class.cfl-type]="category.type==='cfl'"
                                             [class.societe-type]="category.type==='society'">
                                            <span>{{'event.type.'+category.type | translate}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <!-- Third page -->
        <div class="container-fluid" *ngIf="pageNumber === 2">
            <div class="row">
                <div class="col-12 choose-event-header">
                    <h5>Vous avez sélectionné la catégorie : {{selectedCategory.name}}</h5>
                    <button shape="round"
                            status="primary"
                            class="ml-2"
                            (click)="onChangeCategory()"
                            nbButton>
                        Changer de catégorie
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <app-loading *ngIf="isLoadingEvents; else showEvents"></app-loading>
                    <ng-template #showEvents>
                        <div class="list-container">
                            <div class="col-xl-3 col-12 list-col" *ngFor="let event of events">
                                <div class="list-box card-shadow pointer" (click)="onClickEvent(event)">
                                    <span class="list-title" nbTooltip="{{event.title}}" >
                                        {{ event.title }}
                                    </span>
                                    <div class="flex-aligned mt-2 mb-3">
                                        <div [class.cfl-type]="event.type==='cfl'"
                                             [class.societe-type]="event.type==='society'">
                                            <span>{{'event.type.'+ event.type | translate}}</span>
                                        </div>
                                    </div>
                                    <div class="flex-aligned">
                                        <span>
                                            {{ shortAddress(event.address) }}
                                        </span>
                                    </div>
                                    <div class="flex-aligned mt-3" nbTooltip="Créer un événement à partir de ce modèle" >
                                        <span class="flex-aligned bold">Cliquez pour plus d'informations</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <!-- Last page -->
        <div class="container-fluid" *ngIf="pageNumber===3">
            <!-- Type name -->
            <div class="row mb-3">
                <div class="col-3">
                    <span class="bold">Type</span>
                </div>
                <div class="col-9">
                    {{'event.type.'+ selectedEvent.type | translate}}
                </div>
            </div>
            <!-- Category name -->
            <div class="row mb-3">
                <div class="col-3">
                    <span class="bold">Catégorie</span>
                </div>
                <div class="col-9">
                    {{selectedCategory.name}}
                </div>
            </div>
            <!-- Limite de place -->
            <div class="row mb-3">
                <div class="col-3">
                    <span class="bold">Limite de place</span>
                </div>
                <div class="col-9">
                    {{selectedEvent.userLimit || 'Aucune limite'}}
                </div>
            </div>
            <!-- Price -->
            <div class="row mb-3">
                <div class="col-3">
                    <span class="bold">Prix</span>
                </div>
                <div class="col-9 flex-column">
                    <span *ngIf="selectedEventPrices.length <= 0">
                        Pas de prix
                    </span>
                    <ng-container *ngIf="selectedEventPrices.length > 0">
                        <span *ngFor="let price of selectedEventPrices">
                            {{price.name | titlecase}} : {{price.value}} €
                        </span>
                    </ng-container>
                </div>
            </div>
            <!-- Full adresse -->
            <div class="row mb-3">
                <div class="col-3">
                    <span class="bold">Adresse</span>
                </div>
                <div class="col-9 flex-column">
                        <span>{{ selectedEvent.address.name }}</span>
                        <span *ngIf="selectedEvent.address.houseNumber || selectedEvent.address.street">
                            {{ selectedEvent.address.houseNumber }} {{ selectedEvent.address.street }}
                        </span>
                        <span *ngIf="selectedEvent.address.zipcode || selectedEvent.address.city || selectedEvent.address.country">
                            {{ selectedEvent.address.zipcode }} {{ selectedEvent.address.city }} {{ selectedEvent.address.country }}
                        </span>
                </div>
            </div>
            <!-- Details -->
            <div class="row mb-3">
                <div class="col-3">
                    <span class="bold">Détails</span>
                </div>
                <div class="col-9 ck-content" [innerHTML]="selectedEvent.details" *ngIf="selectedEvent.details; else noDetails">
                </div>
                <ng-template #noDetails>
                    <div class="col-9">
                        Pas de détails pour cet événement.
                    </div>
                </ng-template>
            </div>
        </div>
    </nb-card-body>
    <nb-card-footer class="dialog-footer" *ngIf="pageNumber===3">
        <div class="btn-dialog-group">
            <button nbButton
                    status="primary"
                    size="medium"
                    ghost
                    class="mr-1"
                    (click)="onChangeEvent()">
                Sélectionner un autre événement
            </button>
            <button nbButton
                    status="primary"
                    size="medium"
                    shape="round"
                    (click)="onValidate()">
                Confirmer
            </button>
        </div>
    </nb-card-footer>
</nb-card>
