import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import { AuthService } from '../../services/access/auth.service';

@Injectable({
  providedIn: 'root',
})
/*
    Guard pour empêcher un non-admin d'accéder à la page d'admin
*/
export class AdminGuard implements CanActivate {
  constructor(
      private readonly authService: AuthService,
      private readonly _router: Router) { }

  canActivate(): boolean {
    if (this.authService.isAdminCfl() || this.authService.isAdminSociety()) {
      return true;
    } else this._router.navigate(['/']);
  }
}
