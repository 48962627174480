import {Component, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {CategoryService} from '../../../core/services/category/category.service';
import {Category} from '../../interfaces/category';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastersService} from '../../../core/services/toasters/toasters.service';
import {EventService} from '../../../core/services/event/event.service';
import {ActEventPage} from '../../interfaces/pages/act-event.page';
import {Event} from '../../interfaces/event';
import {Address} from '../../interfaces/address';
import {Price} from '../../interfaces/price';
import {Router} from '@angular/router';

@Component({
  selector: 'app-create-event-dialog',
  templateUrl: './create-event-dialog.component.html',
  styleUrls: ['./create-event-dialog.component.scss']
})
export class CreateEventDialogComponent implements OnInit {
  private readonly _searchCatSubject$: Subject<string>;
  selectedCategory: Category = undefined;
  selectedEvent: Event = undefined;
  selectedEventPrices: Price[] = [];
  searchCategory: string;
  isLoadingCategory = false;
  isLoadingEvents = false;
  categories: Category[] = [];
  events: Event[] = [];
  isFixedWidth = false;
  pageNumber = 0;
  title: string;

  constructor(private readonly _dialogRef: NbDialogRef<any>,
              private readonly _categoryService: CategoryService,
              private readonly _eventService: EventService,
              private readonly _toastersService: ToastersService,
              private readonly _router: Router) {
    this.title = 'Création d\'un nouvel événement';
    this._searchCatSubject$ = new Subject<string>();
  }

  ngOnInit(): void {
    this.searchCategory = '';
    this._searchCatSubject$.pipe(
        debounceTime(500)
    ).subscribe(() => this._searchCategory());
  }

  /* PRIVATE METHODS */

  private _searchCategory(): void {
    this.isLoadingCategory = true;
    this._categoryService.searchCategoryWithoutFilter(this.searchCategory)
        .subscribe(
            (categories: Category[]) => {
              this.categories = categories;
              this.isLoadingCategory = false;
            },
            (error: HttpErrorResponse) => {
              this._toastersService.showErrorToast(error);
              this.isLoadingCategory = false;
            },
        );
  }

  private _setPrices(pricesJSON: {}): void {
    Object.keys(pricesJSON).forEach((key) => {
      this.selectedEventPrices.push({
        name: key,
        value: pricesJSON[key]
      });
    });
  }

  private _fetchEvents(): void {
    this.isLoadingEvents = true;
    this._eventService.fetchByCategory(this.selectedCategory.idCategory).subscribe(
        ((value: ActEventPage) => this.events = value.content),
        (error: HttpErrorResponse) => {
          this.isLoadingEvents = false;
          this._toastersService.showErrorToast(error);
        },
        () => this.isLoadingEvents = false
    );
  }

  /* INIT METHODS */

  /* ON USER ACTION METHODS */

  onCatalogChoice(): void {
    this.title = 'Choisir le modèle d\'événement';
    this.isFixedWidth = true;
    this.pageNumber = 1;
    this._searchCategory();
  }

  onClose(): void {
    this._dialogRef.close();
  }

  onValidate(): void {
    this._router.navigateByUrl('/events/create', { state: { data: this.selectedEvent, modify: false } })
        .finally();
    this._toastersService.showSuccessToaster('Modèle d\'événement utilisé avec succès');
    this._dialogRef.close();
  }

  onClickCategory(category: Category): void {
    this.selectedCategory = category;
    this.pageNumber = 2;
    this._fetchEvents();
  }

  onChangeCategory(): void {
    this.selectedCategory = undefined;
    this.events = [];
    this.pageNumber = 1;
  }

  onClickEvent(event: Event): void {
    this.isFixedWidth = false;
    this.selectedEvent = event;
    this._setPrices(JSON.parse(this.selectedEvent.prices));
    this.pageNumber = 3;
  }

  onChangeEvent(): void {
    this.isFixedWidth = true;
    this.selectedEventPrices = [];
    this.selectedEvent = undefined;
    this.pageNumber = 2;
  }

  /* OTHER METHODS */

  applySearchCatString(): void {
    this._searchCatSubject$.next();
  }

  shortAddress(address: Address) {
    let shortenedAddress: string;
    if (address.city && address.country) {
      shortenedAddress = `${address.city}, ${address.country}`;
    } else {
      shortenedAddress = `${address.name}`;
    }
    return shortenedAddress;
  }
}
