import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ToastersService } from 'src/app/core/services/toasters/toasters.service';
import { Router } from '@angular/router';
import { SaleResponse } from 'src/app/shared/interfaces/sale-response';

@Component({
  selector: 'app-dashboard-popup-to-pay',
  templateUrl: './dashboard-popup-to-pay.component.html',
  styleUrls: ['./dashboard-popup-to-pay.component.scss']
})
export class DashboardPopupToPayComponent implements OnInit, OnChanges {

  @Input()
  ticketsToPay: SaleResponse[];

  userResponse = [];

  constructor(private readonly _dialogRef: NbDialogRef<any>,
              private readonly toastersService: ToastersService,
              private readonly router: Router) { }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.event) {
      this.ticketsToPay = [];
    }
  }

  ngOnInit(): void {
    this.ticketsToPay.forEach((element) => {
      const response = { hasPayed: false, idSale: element.idSale };
      this.userResponse.push(response);
    });
  }

  /* PRIVATE METHODS */

  /* INIT METHODS */

  /* ON USER ACTION METHODS */

  onClose(): void {
    this._dialogRef.close();
  }

  onValidate() {
    this._dialogRef.close(this.userResponse);
  }

  /* OTHER METHODS */
}
