import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'changelog',
  templateUrl: './changelog.component.html',
})

export class ChangelogComponent {

  constructor(
    private readonly _dialogRef: NbDialogRef<any>
  ) { }

  onClose(): void {
    this._dialogRef.close();
  }

}
