import { Component, Input, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthService } from '../../../core/services/access/auth.service';
import { NbDialogService } from '@nebular/theme';
import { ChangelogComponent } from './changelog/changelog.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
/*
    Gestion de la navbar du site
*/
export class NavbarComponent implements OnInit {
  currentUrl: string;
  isEventPage: boolean;
  isAdmin: boolean;
  @Input()
  mobile: boolean;
  title = 'newActInTech';
  name: string;
  authenticated: boolean;
  imageUrl: any;
  currentEvent: string;

  constructor(
      private readonly router: Router,
      private readonly authService: AuthService,
      private readonly _dialogService: NbDialogService
  ) { }

  async ngOnInit(): Promise<void> {
    this.currentUrl = this.router.url;
    this.isEventPage = false;
    this.currentEvent = "none";
    this.updateEvent();

    this.router.events.subscribe(
        (result) => {
          if (result instanceof NavigationStart) {
            this.currentUrl = result.url;
            if(this.currentUrl.includes('/events')){
              this.isEventPage = true;
            } else {
              this.isEventPage = false;
            }
          }
        }
    );
    
    this.authenticated = this.authService.isUserLoggedIn();
    this.isAdmin = this.authService.isAdminCfl() || this.authService.isAdminSociety();
    const splitName: string[] = this.authService.getDisplayName().split(' ');
    if (splitName.length >= 2) {
      this.name = `${splitName[0].charAt(0)}${splitName[1].charAt(0)}`;
    }
    this.readProfilePicture();
  }

  readProfilePicture(): void {
    this.authService.getUserPicture()
    .then( blob => {
      this.readFile(blob)
      .then(result => this.imageUrl = result)
    })
    .catch(() => {});
  }

  /** Decode profile picture blob to base64 file */
  readFile(file){
    return new Promise((resolve, reject) => {
      let fr = new FileReader();  
      fr.onload = () => {
        resolve(fr.result)
      };
      fr.onerror = reject;
      fr.readAsDataURL(file);
    });
  }


  onLogout(): void {
    this.authService.logout()
  }

  openChangelog(): void {
    this._dialogService.open(ChangelogComponent, {
      hasBackdrop: true,
    }).onClose.subscribe();
  }

  updateEvent():void{
    const today = new Date();
    const currentYear = today.getFullYear();
    
    const startDateHalloween = new Date(currentYear, 9, 20); // 9 correspond à octobre (mois commence à 0)
    const endDateHalloween = new Date(currentYear, 10, 5);   // 10 correspond à novembre (mois commence à 0)
    
    // Vérifier si la date d'aujourd'hui est entre les bornes
    if(today >= startDateHalloween && today <= endDateHalloween){
      this.currentEvent = "Halloween";
      return;
    };

    const startDateChristmas = new Date(currentYear, 11, 1);
    const endDateChristmas = new Date(currentYear, 11, 31);
    
    if(today >= startDateChristmas && today <= endDateChristmas){
      this.currentEvent = "Christmas";
      return;
    };

    const startDateSummer = new Date(currentYear, 6, 1);
    const endDateSummer = new Date(currentYear, 7, 31);
    
    if(today >= startDateSummer && today <= endDateSummer){
      this.currentEvent = "Summer";
      return;
    };
  } 

}
