import { SafeHtmlPipe } from './../pipes/safe-html.pipe';
import {NgModule} from '@angular/core';
import {FirstWordPipe} from '../pipes/first-word.pipe';
import {SecondWordPipe} from '../pipes/second-word.pipe';
import {DotIntoSpace} from '../pipes/dot-into-space.pipe';
import { TypeFromBase64 } from '../pipes/type-from-base64.pipe';

@NgModule({
    declarations: [
        FirstWordPipe,
        SecondWordPipe,
        SafeHtmlPipe,
        DotIntoSpace,
        TypeFromBase64
    ],

    imports: [],

    exports: [
        FirstWordPipe,
        SecondWordPipe,
        SafeHtmlPipe,
        DotIntoSpace,
        TypeFromBase64
    ]
})

export class PipesModule {}
