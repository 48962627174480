import {HostListener, Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MobileMenuGuard implements CanActivate {
  isMobile: boolean;

  constructor(private readonly _router: Router) {
    this.isMobile = window.screen.width  < environment.breakpoints.extraLarge;
  }

  @HostListener('window:resize', ['$event'])
  private _onResize(event): void {
    this.isMobile = event.target.screen.width < environment.breakpoints.extraLarge;
  }

  canActivate(): boolean {
    if (!this.isMobile) {
      this._router.navigate(['/'])
          .finally();
    }
    return this.isMobile;
  }

}
