import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  @Input()
  title: string;
  @Input()
  subtitle: string;
  @Input()
  content: string;
  form: FormGroup;

  constructor(private readonly _dialogRef: NbDialogRef<any>,
              private readonly _formBuilder: FormBuilder) {
    this.title = 'Confirmer';
    this.subtitle = '';
    this.content = '';
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      userInput: new FormControl('', Validators.maxLength(250))
    });
  }

  /* PRIVATE METHODS */
  /* INIT METHODS */
  /* ON USER ACTION METHODS */

  onClose() {
    this._dialogRef.close({
      submit: false,
      userInput: ''
    });
  }

  onSubmit() {
    this._dialogRef.close({
      submit: true,
      userInput: this.form.value.userInput,
    });
  }

  /* OTHER METHODS */
  getFormControl(): string{
    return this.form.value.userInput;
  }
}
