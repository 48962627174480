import {Injectable} from '@angular/core';
import {NbToastrService} from '@nebular/theme';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ToastersService {

  constructor(private readonly _toastrService: NbToastrService) { }

  showCustomErrorToast(titre: string, error: string): void {
    this._toastrService.show(error, titre, {status: 'danger'});
  }

  showErrorToast(error: HttpErrorResponse): void {
    if (error.status === 0) {
      this._toastrService.show('Une erreur inconnue est survenue', 'Erreur', {status: 'danger'});
    }
    this._toastrService.show(error.error.message, 'Erreur', {status: 'danger'});
  }

  showSuccessToaster(message: string): void {
    this._toastrService.show(message, 'Confirmation', {status: 'success'});
  }

  showWarningToaster(message: string): void {
    this._toastrService.show(message, 'Attention', {status: 'warning'});
  }
}
