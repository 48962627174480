import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnChanges, AfterViewInit, OnDestroy {
  private _map: L.Map;
  private _marker: L.Marker;
  @Input()
  markerLng: number;
  @Input()
  markerLtd: number;
  @Input()
  popUpText: string;
  @ViewChild('mapContainer')
  mapContainer: ElementRef;

  constructor(private readonly _renderer: Renderer2,
              private readonly _elRef: ElementRef) {
    this.markerLtd = 49.497028;
    this.markerLng = 6.050804;
    this.popUpText = 'InTech';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.markerLng || changes.markerLtd || changes.popUpText)
        && (this._map && this._marker)) {
      this._majMap();
    }
  }

  ngAfterViewInit() {
    this._initMap();
    this._map.invalidateSize();
  }

  ngOnDestroy() {
    this._map.remove();
  }

  /* PRIVATE METHODS */

  private _majMap(): void {
    this._map.setView([this.markerLtd, this.markerLng], 16);
    this._marker.setLatLng([this.markerLtd, this.markerLng]);
    this._marker.setPopupContent(this.popUpText || 'Veuillez sélectionner une adresse.');
  }

  private _initMap(): void {
    const markerIcon = L.icon({
      iconUrl: 'assets/marker-icon.png',
      shadowUrl: 'assets/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [13, 15],
    });
    if (!this._map) {
      const div = this._renderer.createElement('div');
      this._renderer.setProperty(div, 'id', 'customMap');
      this._renderer.appendChild(this._elRef.nativeElement, div);
      this._map = L.map(div);
      this._map = this._map.setView([this.markerLtd, this.markerLng], 16);
      L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(this._map);
      this._marker = L.marker([this.markerLtd, this.markerLng], {icon: markerIcon}).addTo(this._map);
      this._marker.bindPopup(this.popUpText || 'Veuillez sélectionner une adresse.').openPopup();
    }
  }

  /* INIT METHODS */

  /* ON USER ACTION METHODS */

  /* OTHER METHODS */
}
