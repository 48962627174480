import * as $ from 'jquery';

import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CalendarEvents } from '../../shared/interfaces/calendar-events';
import { Constants } from 'src/app/shared/constant/app.constants';
import { CreateEventDialogComponent } from '../../shared/components/create-event-dialog/create-event-dialog.component';
import { DashboardPopupToPayComponent } from '../dashboard-popup-to-pay/dashboard-popup-to-pay.component';
import { DatePipe } from '@angular/common';
import { debounceTime } from 'rxjs/operators';
import { Event } from '../../shared/interfaces/event';
import { fromEvent, Subscription } from 'rxjs';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { NbDialogService } from '@nebular/theme';
import { Router } from '@angular/router';
import { SaleResponse } from 'src/app/shared/interfaces/sale-response';
import { SaleService } from 'src/app/core/services/sale/sale.service';
import { ToastersService } from 'src/app/core/services/toasters/toasters.service';

import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';

@Component({
  selector: 'app-dashboard-calendar',
  templateUrl: './dashboard-calendar.component.html',
  styleUrls: ['./dashboard-calendar.component.scss'],
})
/*
    Gestion du calendrier affiché dans le dashboard
*/
export class DashboardCalendarComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('calendar')
  calendarComponent: FullCalendarComponent;

  private readonly _header: {} = {
    start: 'prev',
    center: 'title',
    end: 'next',
  };
  // private readonly _calendarPlugins = [dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin];
  private readonly _calendarPlugins = [dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin];
  private _calendarEvents: CalendarEvents[];
  private calendarHolidaysEvents: CalendarEvents[];
  private currentDate: Date | number = Date.now();
  private readonly _typeColor = {};
  
  public toggleHolidaysEvent: boolean;
  public calendarOptions: any;
  public ticketsToPay: SaleResponse[] = [];
  public schoolHoliday = 'french-holiday';

  @Input()
  windowVersion: string;
  @Input()
  events: Event[] = [];

  windowResizeSubscription: Subscription;

  constructor(
    private readonly _datePipe: DatePipe,
    private readonly _router: Router,
    private readonly _dialogService: NbDialogService,
    private readonly _saleService: SaleService,
    private readonly _toasterService: ToastersService
  ) {
    this._typeColor = {
      holiday: 'darkgrey',
      cfl: '#0EAA92',
      society: '#B08BEB',
      frenchHoliday: 'seashell',
    };

    let toggleFromSession = sessionStorage.getItem(Constants.SESSION_STORAGE.CALENDAR);
    this.toggleHolidaysEvent = toggleFromSession === null ? false : toggleFromSession.toLowerCase() == 'true';
  }

  ngOnInit(): void {
    this._initCalendar();
    this.initTicketsToPay();

    this.windowResizeSubscription = fromEvent(window, 'resize')
      .pipe(debounceTime(1500))
      .subscribe(() => {
        this.onWindowResize();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.events) {
      const changeEvents: Event[] = changes.events.currentValue;
      this._calendarEvents = changeEvents.map((event: Event) =>
        Object.assign(
          {},
          {
            id: event.idActEvent,
            title: event.title,
            description: event.title,
            date: this._datePipe.transform(event.startDate, 'yyyy-MM-dd'),
            start: event.startDate,
            end: event.endDate,
            type: event.type,
            allDay: event.type === 'holiday' || event.type === this.schoolHoliday,
            display: event.type === this.schoolHoliday ? 'background' : '',
            color: this.getEventColor(event.type),
          }
        )
      );
      if (this.calendarOptions) {
        if(this.toggleHolidaysEvent){
          this.backupHolidays(true);
        }

        this.calendarOptions.events = this._calendarEvents;
      }
    }
    if (changes.windowVersion) {
      this.windowVersion = changes.windowVersion.currentValue;
      if (this.calendarComponent) {
        this._onWindowChange();
      }
    }
  }

  ngAfterViewInit() {
    this.onWindowResize();
  }

  ngOnDestroy(): void {
    if (this.windowResizeSubscription) {
      this.windowResizeSubscription.unsubscribe();
    }
  }

  /* INIT METHODS */
  private initTicketsToPay(): void {
    this._saleService.getTicketsToPay().subscribe(
      (response) => (this.ticketsToPay = response),
      () => {}
    );
  }

  private _initCalendar(): void {
    this.calendarOptions = {
      handleWindowResize: false,
      headerToolbar: this._header,
      plugins: this._calendarPlugins,
      initialView: 'dayGridMonth',

      fixedWeekCount: false,
      locale: 'fr',
      firstDay: 1,
      buttonText: {
        today: "Aujourd'hui",
        month: 'Mois',
        week: 'Semaine',
      },
      height: this.windowVersion === 'mobile' ? 'auto' : '90%',
      contentHeight: this.windowVersion === 'mobile' ? 'auto' : '',
      views: {
        dayGridMonth: {
          dayMaxEvents: true,
        },
        listMonth: {
          listDayFormat: {
            weekday: 'long',
          },
          listDaySideFormat: {
            month: 'long',
            year: 'numeric',
            day: 'numeric',
          },
          noEventsContent: 'Aucun événement prévu',
        },
      },
      displayEventTime: false,
      eventDisplay: 'block',
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false,
        omitZeroMinute: false,
      },
      events: this._calendarEvents,
      moreLinkContent: (arg: any) => {
        return `+${arg.num} événements`;
      },
      eventClick: (info: any) => {
        if (
          info.event._def.extendedProps.type !== 'holiday' &&
          info.event._def.extendedProps.type !== 'french-holiday'
        ) {
          this._router.navigate([`events/info/${info.event._def.publicId}`]);
        }
      },
      eventMouseEnter: this._onMouseEnter.bind(this),
      initialDate: this.currentDate,
    };
  }

  /* PRIVATE METHODS */
  private _onMouseEnter(info: any): void {
    // tooltip
    if (info.event._def.extendedProps.type !== 'holiday' && info.event._def.extendedProps.type !== 'french-holiday') {
      const tip = info.el;
      tip.setAttribute('data-toggle', 'tooltip');
      tip.setAttribute('title', `Plus d'informations sur :  ${info.event.title}`);
      $(tip)
        .css('cursor', 'pointer')
        .on('hover', () => {
          ($(tip) as any).tooltip();
        });
    }
  }

  private _onWindowChange(): void {
    if (this.windowVersion === 'mobile') {
      // Bootstrap media query
      this.calendarComponent.getApi().changeView('listMonth');
      this.calendarComponent.getApi().setOption('contentHeight', 'auto');
      this.calendarComponent.getApi().setOption('height', 'auto');
    } else if (this.windowVersion === 'browser') {
      // Bootstrap media query
      this.calendarComponent.getApi().changeView('dayGridMonth');
      this.calendarComponent.getApi().setOption('contentHeight', '');
      this.calendarComponent.getApi().setOption('height', '95%');
    }
  }

  private onWindowResize(): void {
    if (this.windowVersion === 'mobile') {
      this.calendarComponent.getApi().changeView('listMonth');
      this.calendarComponent.getApi().setOption('contentHeight', 'auto');
      this.calendarComponent.getApi().setOption('height', 'auto');
    }
  }

  /* ON USER ACTION METHODS */
  onCreateEventClick(): void {
    this._dialogService
      .open(CreateEventDialogComponent, {
        hasBackdrop: true,
      })
      .onClose.subscribe();
  }

  onShowTicketsToPay(): void {
    const ticketsToPay = this.ticketsToPay;
    this._dialogService
      .open(DashboardPopupToPayComponent, {
        hasBackdrop: true,
        context: { ticketsToPay },
      })
      .onClose.subscribe((response) => {
        if (response) {
          // if user has updated anything
          response.forEach((element: any) => {
            if (element.hasPayed) {
              // update each ticket user has modified
              this._saleService.updateTicketsToPay(element.idSale).subscribe(
                () => {
                  this._toasterService.showSuccessToaster('Vous avez indiqué avoir payé votre billet');
                  this.initTicketsToPay(); // refresh page content
                },
                () => this._toasterService.showWarningToaster('Il y a une erreur lors de la modification')
              );
            }
          });
        }
      });
  }

  /* OTHER METHODS */
  public getEventColor(type: string): string {
    if (type === this.schoolHoliday) {
      type = 'frenchHoliday';
    }
    return this._typeColor[type];
  }

  public disableHolidays(checked: boolean): void {
    // if enable checkbox
    // --> holidaysEvents and current date are saved to be used later
    // --> holidaysEvents are removed from calendarEvents
    // if disable checkox
    // --> holidaysEvents are added to calendarEvents
    // Then : re-init calendar
    const currentDateString = `${this.calendarComponent.getApi().getDate().getFullYear()}-
      ${this.calendarComponent.getApi().getDate().getMonth() + 1}-01`;
    this.currentDate = new Date(currentDateString);
    sessionStorage.setItem(Constants.SESSION_STORAGE.CALENDAR, JSON.stringify(checked));

    this.backupHolidays(checked);
    this._initCalendar();
  }

  private backupHolidays(checked: boolean){
    if (checked) {
      const holidayType = this.schoolHoliday;
      this.calendarHolidaysEvents = this._calendarEvents.filter((item) => item.type === holidayType);
      this._calendarEvents = this._calendarEvents.filter((item) => item.type !== holidayType);
    } else {
      this._calendarEvents = this._calendarEvents.concat(this.calendarHolidaysEvents);
    }
  }
}
