import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RoutingBackService} from '../routing-back/routing-back.service';
import {Observable} from 'rxjs';
import {CustomDocument} from '../../../shared/interfaces/custom-document';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private readonly _http: HttpClient,
              private readonly _routingBackService: RoutingBackService) {}

  findOneDocumentFromEvent(idEvent: number): Observable<CustomDocument> {
    return this._http.get(this._routingBackService.backendapi.eventDocument
      .replace(':id', idEvent));
  }

  findThumbnailEvent(idEvent: number): Observable<Blob> {
    return this._http.get(this._routingBackService.backendapi.eventThumbnail
      .replace(':id', idEvent), { responseType: 'blob' });
  }
}
