<nb-card class="mail-dialog">
    <nb-card-header class="dialog-header">
        <h3>Envoi d'un mail aux participants</h3>
    </nb-card-header>

    <nb-card-body>
        <form [formGroup]="form">
            <div class="row">
                <div class="col-12">
                    <p>Contacter les participants avec le status : </p>

                    <div class="checkbox-container">
                        <button status="primary"
                                (click)="onReset()"
                                ghost nbButton>
                            <nb-icon icon="sync-outline"></nb-icon>
                        </button>
                        <nb-checkbox class="nb-checkbox"
                                     formControlName="validated"
                                     (checkedChange)="onCheckValidated($event)">
                            Acceptés
                        </nb-checkbox>
                        <nb-checkbox class="nb-checkbox"
                                     formControlName="pending"
                                     (checkedChange)="onCheckPending($event)">
                            En attente
                        </nb-checkbox>
                        <nb-checkbox class="nb-checkbox"
                                     formControlName="unavailable"
                                     (checkedChange)="onCheckUnavailable($event)">
                            Indisponibles
                        </nb-checkbox>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-12">
                    <label for="receivers">Destinataire(s)</label>
                    <textarea nbInput
                                id="receivers"
                                fullWidth
                                formControlName="mailList"
                                placeholder="mail@intech.lu séparé par un ;"
                                [status]="getFieldStatus('mailList')"
                                required></textarea>
                    <div class="invalid-field"
                         *ngIf="(form.get('mailList').touched || validationError) && form.get('mailList').hasError('required')">
                        Le champ destinataire(s) est obligatoire.
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-12">
                    <label for="subject">Sujet</label>
                    <input nbInput
                            id="subject"
                            fullWidth
                            formControlName="subject"
                            placeholder="Sujet du mail"
                            [status]="getFieldStatus('mailList')"
                            required/>
                    <div class="invalid-field"
                         *ngIf="(form.get('subject').touched || validationError) && form.get('subject').hasError('required')">
                        Le champ du sujet est obligatoire.
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12 editor-container">
                    <label class="mb-1">Message</label>
                    <ckeditor [editor]="editor" [config]="editorConfig" formControlName="message"></ckeditor>
                </div>
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer class="dialog-footer">
        <div class="btn-dialog-group">
            <button status="primary"
                    size="medium"
                    ghost
                    class="mr-md-1"
                    (click)="onCancel()"
                    nbButton>Annuler</button>

            <button class="btn-admin add-event"
                    size="medium"
                    shape="round"
                    [nbSpinner]="loadingMail"
                    nbSpinnerStatus="primary"
                    (click)="onSubmit()"
                    nbButton>Confirmer</button>
        </div>
    </nb-card-footer>
</nb-card>
