import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {RoutingBackService} from '../routing-back/routing-back.service';
import {Observable} from 'rxjs';
import {FeatureCollection} from '../../../shared/interfaces/feature-collection';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  constructor(
      private readonly _http: HttpClient,
      private readonly _routingBackService: RoutingBackService) { }

  searchPlaces(searchPlace: string): Observable<FeatureCollection> {
    const params = new HttpParams().set('searchPlace', searchPlace);
    return this._http.get<FeatureCollection>(this._routingBackService.backendapi.searchPlace, { params });
  }
}
