<app-loading *ngIf="!isComponentLoaded()"></app-loading>
<div class="container-fluid" [class.mobile]="isMobile()">
    <div class="row dashboard-row">
        <!-- Main content -->
        <div class="col-xl-8 col-12 dashboard-col-1 main-page">
            <app-dashboard-calendar class="dashboard-calendar"
                                    [events]="events"
                                    [windowVersion]="windowVersion">
            </app-dashboard-calendar>
        </div>
        <!-- Side bar -->
        <div class="col-xl-4 col-12 flex-column dashboard-col-2 right-column">
            <app-dashboard-myevents class="d-flex dashboard-my-events"
                                    (loadingFinished)="onLoadingFinishedMyEvents()"
                                    [mobile]="isMobile()">
            </app-dashboard-myevents>
            <app-dashboard-ticketing class="flex-column justify-content-md-around dashboard-tickets"
                                    (loadingFinished)="onLoadingFinishedTickets()"
                                    *ngIf="windowVersion==='browser'">
            </app-dashboard-ticketing>
        </div>
    </div>
</div>
