import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
// Nebular
import {NebularModule} from './nebular.module';
import {PipesModule} from './pipes.modules';
import {CommonModule} from '@angular/common';
import {FullCalendarModule} from '@fullcalendar/angular';
import {DocumentAddComponent} from '../components/document-add/document-add.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {RouterModule} from '@angular/router';
import {NavbarComponent} from '../components/navbar/navbar.component';
import {MapComponent} from '../components/map/map.component';
import {AutoPlacesComponent} from '../components/auto-places/auto-places.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {NavbarMobileComponent} from '../components/navbar/navbar-mobile/navbar-mobile.component';
import {TagInputModule} from 'ngx-chips';
import {DataTablesModule} from 'angular-datatables';
import {NotFoundComponent} from '../components/not-found/not-found.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {TranslateModule} from '@ngx-translate/core';
import {LoadingComponent} from '../components/loading/loading.component';
import {NbDatepickerModule} from '@nebular/theme';
import {NbDateFnsDateModule} from '@nebular/date-fns';
import {ConfirmationDialogComponent} from '../components/confirmation-dialog/confirmation-dialog.component';
import {MailDialogComponent} from '../components/mail-dialog/mail.dialog.component';
import {CreateEventDialogComponent} from '../components/create-event-dialog/create-event-dialog.component';
import {MobileDirective} from '../directives/mobile.directive';
import {DigitOnlyDirective} from '../directives/digit-only.directive';
import { ChangelogComponent } from '../components/navbar/changelog/changelog.component';

@NgModule({
    declarations: [
        MobileDirective,
        CreateEventDialogComponent,
        DocumentAddComponent,
        NavbarComponent,
        ChangelogComponent,
        MapComponent,
        AutoPlacesComponent,
        NavbarMobileComponent,
        NotFoundComponent,
        LoadingComponent,
        ConfirmationDialogComponent,
        MailDialogComponent,
        DigitOnlyDirective,
    ],
    imports: [
        CommonModule,
        PipesModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        HttpClientModule,
        FullCalendarModule,
        CKEditorModule,
        NgxPaginationModule,
        TagInputModule,
        DataTablesModule,
        NgxDatatableModule,
        TranslateModule,
        NebularModule,
    ],
    exports: [
        MobileDirective,
        CommonModule,
        PipesModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        FullCalendarModule,
        DocumentAddComponent,
        NavbarComponent,
        ChangelogComponent,
        CKEditorModule,
        RouterModule,
        MapComponent,
        AutoPlacesComponent,
        NgxPaginationModule,
        NavbarMobileComponent,
        DataTablesModule,
        TagInputModule,
        NotFoundComponent,
        NgxDatatableModule,
        TranslateModule,
        LoadingComponent,
        NebularModule,
        NbDatepickerModule,
        NbDateFnsDateModule,
        ConfirmationDialogComponent,
        MailDialogComponent,
        CreateEventDialogComponent,
        DigitOnlyDirective,
    ]
})

export class SharedModule { }
