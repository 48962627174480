import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {Feature} from '../../interfaces/feature';
import {MapService} from '../../../core/services/map/map.service';
import {debounceTime} from 'rxjs/operators';
import {FeatureCollection} from '../../interfaces/feature-collection';
import {Properties} from '../../interfaces/properties';

@Component({
  selector: 'app-auto-places',
  templateUrl: './auto-places.component.html',
  styleUrls: ['./auto-places.component.scss']
})
export class AutoPlacesComponent implements OnInit {
  private _searchSubject$: Subject<string>;
  private readonly _feature$: EventEmitter<Feature>;
  private readonly _defaultAddressClick$: EventEmitter<void>;
  features: Feature[];
  private readonly _addressPattern: string;
  isLoading: boolean;
  searchPlace: string;

  constructor(private readonly _mapService: MapService) {
    this._addressPattern = ':houseNumber :street :code :city :country';
    this._feature$ = new EventEmitter<Feature>();
    this._defaultAddressClick$ = new EventEmitter<void>();
  }

  @Output('feature')
  get feature$(): EventEmitter<Feature> {
    return this._feature$;
  }

  @Output()
  get defaultAddressClick(): EventEmitter<void> {
    return this._defaultAddressClick$;
  }

  ngOnInit(): void {
    this.isLoading = false;
    this.searchPlace = '';
    this.features = [];
    this._searchSubject$ = new Subject<string>();
    this._searchSubject$.pipe(
        debounceTime(400)
    ).subscribe(
        () => this._autoComplete(),
        () => {}
    );
  }

  /* PRIVATE METHODS */

  private _autoComplete(): void {
    if (this.searchPlace !== '') {
      this.isLoading = true;
      this._mapService.searchPlaces(this.searchPlace)
          .subscribe(
              (res: FeatureCollection) => {
                this.features = res.features;
                this.isLoading = false;          
              },
          );

    }
  }

  /* INIT METHODS */

  /* ON USER ACTION METHODS */

  onButtonAddressClicked(): void {
    this._defaultAddressClick$.emit();
  }

  onInput(): void {
    this._searchSubject$.next();
  }

  onOptionSelect(feature: Feature): void {
    this._feature$.emit(feature);
  }

  /* OTHER METHODS */

  formatAddress(properties: Properties): string {
    return this._addressPattern
        .replace(':houseNumber', properties.housenumber || ' ')
        .replace(':street', properties.street || ' ')
        .replace(':code', properties.postcode || ' ')
        .replace(':city', properties.city || ' ')
        .replace(':country', properties.country || ' ')
        .replace(/\s{2,}/, '');
  }

}
