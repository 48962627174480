import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RoutingBackService} from '../routing-back/routing-back.service';
import {Observable} from 'rxjs';
import {Mail} from '../../../shared/interfaces/mail';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(
      private readonly _http: HttpClient,
      private readonly _routingBackService: RoutingBackService) { }

  postMail(mailRequest: Mail): Observable<string[]> {
    return this._http.post<string[]>(this._routingBackService.backendapi.mail, mailRequest);
  }
}
