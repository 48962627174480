import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import * as Editor from 'src/assets/js/ckeditor';
import {EventService} from '../../../core/services/event/event.service';
import {EventMailFilter} from '../../interfaces/event-mail.filter';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastersService} from '../../../core/services/toasters/toasters.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MailService} from '../../../core/services/mail/mail.service';
import {Mail} from '../../interfaces/mail';



@Component({
  selector: 'app-mail.dialog',
  templateUrl: './mail.dialog.component.html',
  styleUrls: ['./mail.dialog.component.scss']
})
export class MailDialogComponent implements OnInit {
  // General
  @Input()
  idEvent: number;
  form: FormGroup;
  validationError = false;
  loadingMail = false;

  public editor = Editor;

  editorConfig = {
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', '|', 'undo', 'redo']
  };

  constructor(private readonly _dialogRef: NbDialogRef<any>,
              private readonly _formBuilder: FormBuilder,
              private readonly _toastersService: ToastersService,
              private readonly _eventService: EventService,
              private readonly _mailService: MailService) {
    this.form = this._buildForm();
    this.idEvent = -1;
  }

  ngOnInit(): void {
    this.loadingMail = false;
  }

  /* PRIVATE METHODS */
  private _fetchMails(): void {
    const eventMailFilter: EventMailFilter = {
      validated: this.form.value.validated,
      pending: this.form.value.pending,
      unavailable: this.form.value.unavailable
    };
    this._eventService.fetchMailsFromEvent(this.idEvent, eventMailFilter).subscribe(
        (value: string[]) => this._formatMailList(value),
        (error: HttpErrorResponse) => this._toastersService.showErrorToast(error)
    );
  }

  private _formatMailList(mails: string[]): void {
    let mailList = '';
    mails.forEach((mail: string, index: number) => {
      mailList = mailList.concat(mail, (index < mails.length) ? ';' : '');
    });
    this.form.patchValue({mailList});
  }

  private _buildForm(): FormGroup {
    return this._formBuilder.group({
      validated: new FormControl(false),
      pending: new FormControl(false),
      unavailable: new FormControl(false),
      mailList: new FormControl('', Validators.required),
      subject: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required)
    });
  }

  /* INIT METHODS */

  /* ON USER ACTION METHODS */

  onCheckValidated(value: boolean): void {
    this.form.patchValue({validated: value});
    this._fetchMails();
  }

  onCheckUnavailable(value: boolean): void {
    this.form.patchValue({unavailable: value});
    this._fetchMails();
  }

  onCheckPending(value: boolean): void {
    this.form.patchValue({pending: value});
    this._fetchMails();
  }

  onReset(): void {
    this.form.patchValue({
      validated: false,
      pending: false,
      unavailable: false
    });
    this._fetchMails();
  }

  onSubmit(): void {
    this.validationError = this.form.invalid;
    if (!this.validationError) {
      const mailArray: string[] = this.form.value.mailList.split(';');
      const mail: Mail = {
        idEvent: this.idEvent,
        receivers: mailArray.filter(value => value),
        subject: this.form.value.subject,
        body: this.form.value.message
      };
      this.loadingMail = true;
      this._mailService.postMail(mail).subscribe(
          () => {
            this._toastersService.showSuccessToaster('Mail envoyé !');
            this._dialogRef.close();
          },
          (error: HttpErrorResponse) => {
            this._toastersService.showErrorToast(error);
            this.loadingMail = false;
          }
      );
    }
  }

  onCancel(): void {
    this._dialogRef.close();
  }

  /* OTHER METHODS */

  getFieldStatus(fieldName: string): string {
    if (this.form.get(fieldName).touched || this.validationError) {
      return (this.form.get(fieldName).invalid) ? 'danger' : 'success';
    }
    return 'basic';
  }

}
