import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {RoutingBackService} from '../routing-back/routing-back.service';
import {Observable} from 'rxjs';
import {Registration} from '../../../shared/interfaces/registration';
import {RegistrationPage} from '../../../shared/interfaces/pages/registration.page';
import {PageRequest} from '../../../shared/interfaces/page-request';
import {TotalRegistration} from 'src/app/shared/interfaces/total-registration';
import { UserResponse } from 'src/app/shared/interfaces/user-response';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  typeJson = 'application/json';
  constructor(
      private readonly _http: HttpClient,
      private readonly _routingBackService: RoutingBackService) { }

  fetchParticipants(idEvent: number, page: any): Observable<RegistrationPage> {
    const httpParams = new HttpParams({fromObject: page});
    return this._http.get<RegistrationPage>(this._routingBackService.backendapi.actEventParticipants.replace(':id', idEvent),
        {params: httpParams});
  }

  searchParticipants(idEvent: number, searchRequest: string, page: PageRequest): Observable<RegistrationPage> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchRequest', searchRequest);
    httpParams = httpParams.append('size', `${page.size}`);
    httpParams = httpParams.append('page', `${page.page}`);
    if (page.sort) {
      page.sort.forEach((value: string) => httpParams = httpParams.append('sort', value));
    }
    return this._http.get<RegistrationPage>(this._routingBackService.backendapi.searchParticipants.replace(':id', idEvent),
        {params: httpParams});
  }

  fetchUnavailable(idEvent: number, page: any): Observable<RegistrationPage> {
    const httpParams = new HttpParams({fromObject: page});
    return this._http.get<RegistrationPage>(this._routingBackService.backendapi.actEventUnavailable.replace(':id', idEvent),
        {params: httpParams});
  }

  searchUnavailable(idEvent: number, searchRequest: string, page: PageRequest): Observable<RegistrationPage> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchRequest', searchRequest);
    httpParams = httpParams.append('size', `${page.size}`);
    httpParams = httpParams.append('page', `${page.page}`);
    if (page.sort) {
      page.sort.forEach((value: string) => httpParams = httpParams.append('sort', value));
    }
    return this._http.get<RegistrationPage>(this._routingBackService.backendapi.searchUnavailable.replace(':id', idEvent),
        {params: httpParams});
  }

  fetchUserRegistration(idEvent: number): Observable<Registration> {
    return this._http.get<Registration>(this._routingBackService.backendapi.myRegistration.replace(':id', idEvent), {});
  }

  fetchAllUserResponses(idEvent: number): Observable<UserResponse[]> {
    return this._http.get<UserResponse[]>(this._routingBackService.backendapi.allUserResponses.replace(':id', idEvent), {});
  }

  fetchTotalRegistration(idEvent: number): Observable<TotalRegistration> {
    return this._http.get<TotalRegistration>(this._routingBackService.backendapi.totalRegistration.replace(':id', idEvent), {});
  }

  registerToEvent(registration: Registration): Observable<Registration> {
    return this._http.put<Registration>(this._routingBackService.backendapi.registration, registration,
      { headers: new HttpHeaders(Object.assign({ 'Content-Type': this.typeJson }, {})) });
  }

  updateRegistration(registration: Registration): Observable<Registration> {
    return this._http.put<Registration>(this._routingBackService.backendapi.updateRegistration.replace(':id', registration.idRegistration),
        registration, { headers: new HttpHeaders(Object.assign({ 'Content-Type': this.typeJson }, {})) });
  }

  isUnavailableToEvent(registration: Registration): Observable<Registration> {
    return this._http.put<Registration>(this._routingBackService.backendapi.unavailable, registration,
      { headers: new HttpHeaders(Object.assign({ 'Content-Type': this.typeJson }, {})) });
  }

  alterStatus(registration: Registration): Observable<Registration> {
    return this._http.put<Registration>(this._routingBackService.backendapi.alterStatus, registration,
      { headers: new HttpHeaders(Object.assign({ 'Content-Type': this.typeJson }, {})) });
  }

}
