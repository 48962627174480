<div *ngIf="isAuthenticated">
  <!-- Content -->
  <div id="app-content" class="root">
    <nb-layout id="main-layout" [class.mobile]="mobile">
      <nb-sidebar id="menu-sidebar" *appMobile="'browser'">
        <app-navbar class="w-100" [mobile]="mobile"></app-navbar>
      </nb-sidebar>
      <nb-layout-header id="mobile-header" *appMobile="'mobile'">
        <div class="header-nav-logo">
          <nb-icon class="nb-icon" icon="actintech-mobile-icon" pack="custom-icons"></nb-icon>
        </div>
        <!-- #CR vous pouvez récupérer le logo en tant que fichier SVG et l'intégrer directement comme ça dans le header. ça évitera d'avoir à télécharger la police de caractères -->
      </nb-layout-header>
      <nb-layout-column class="main-content">
        <router-outlet></router-outlet>
      </nb-layout-column>
      <nb-layout-footer id="mobile-footer" fixed *appMobile="'mobile'">
        <app-navbar class="h-100 w-100" [mobile]="mobile"></app-navbar>
      </nb-layout-footer>
    </nb-layout>
  </div>
</div>