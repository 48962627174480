import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-document-add',
  templateUrl: './document-add.component.html',
  styleUrls: ['./document-add.component.scss']
})
/*
    Classe gérant le glisser-déposer d'une image pour le formulaire
*/
export class DocumentAddComponent implements OnInit, OnChanges {
  private _fileType: string;
  @Input() readonly pdfAuthorized: boolean;
  image: SafeUrl;
  fileName: string;
  @Input()
  readonly file: File;
  sizeError: boolean;
  typeError: boolean;
  maxFileSize: number;
  fileOver = false;
  @Input()
  readonly formError: boolean;
  private readonly _fileNameSize$: EventEmitter<void>;
  private readonly _cancel$: EventEmitter<void>;
  private readonly _submit$: EventEmitter<File>;
  readonly form: FormGroup;

  @Output('fileNameSize')
  get fileNameSize$(): EventEmitter<void> {
    return this._fileNameSize$;
  }

  @Output('cancel')
  get cancel$(): EventEmitter<void> {
    return this._cancel$;
  }

  @Output('submit')
  get submit$(): EventEmitter<File> {
    return this._submit$;
  }

  constructor(
      private readonly _http: HttpClient,
      private readonly _sanitizer: DomSanitizer) {
    this._fileNameSize$ = new EventEmitter<void>();
    this._cancel$ = new EventEmitter<void>();
    this._submit$ = new EventEmitter<File>();
    this.form = this._buildForm();
    this._fileType = 'none';
    this.maxFileSize = 2;
    this.sizeError = false;
    this.typeError = false;
  }

  ngOnInit(): void {
    this.image = '';
    this.fileName = 'Ajouter une image';
    if (this.file) {
      this._handleDoc(this.file);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.file) {
      this._handleDoc(this.file);
    }
  }

  /* PRIVATE METHODS */

  private _checkFile(file: File): boolean {
    let fileNameError = false;
    // Check file name
    if (file.name.length > 250) {
      this._fileNameSize$.emit();
      fileNameError = true;
    }
    // Check file size
    const fileSizeMb: number = (file.size / 1000 / 1000);
    this.sizeError = (fileSizeMb >= this.maxFileSize);
    // Check file type
    const authorizedType = this.pdfAuthorized
      ? ['image/jpeg', 'image/svg+xml', 'image/png', 'application/pdf']
      : ['image/jpeg', 'image/svg+xml', 'image/png'];
    this.typeError = !authorizedType.includes(file.type);
    // If one is true, then it return true as an error signal
    return (fileNameError || this.sizeError || this.typeError);
  }

  private _handleDoc(file: File): void {
    if (!this._checkFile(file)) {
      this.sizeError = false;
      this._fileType = file.type;
      this.form.patchValue({
        file,
      });
      this.fileName = file.name;
      const urlImage = URL.createObjectURL(file as Blob);
      this.image = this._sanitizer.bypassSecurityTrustUrl(urlImage);
      this.onSubmit();
    }
  }

  private _buildForm(): FormGroup {
    return new FormGroup({
      file: new FormControl(null, Validators.compose([Validators.required])),
    });
  }

  /* INIT METHODS */

  /* ON USER ACTION METHODS */

  onDragOver(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;
  }

  onDragLeave(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
  }

  onDrop(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      this._handleDoc(file);
    }
  }

  onSubmit(): void {
    const file = this.form.value.file;
    this._submit$.emit(file);
  }

  /* OTHER METHODS */

  upload_file(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this._handleDoc(file);
    }
  }
}
