import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';

/*
    Service pour récupérer les variables depuis environment.ts pour les utiliser proprement dans le code
*/
@Injectable({
  providedIn: 'root'
})
export class RoutingBackService {
  private readonly _backendapi: any;

  constructor() {
    this._backendapi = { };
    const baseUrl =  `${environment.backendUrl}`;
    Object.keys(environment.endpoints).forEach(k => this._backendapi[k] = `${baseUrl}${environment.endpoints[k]}`);
  }

  get backendapi(): any {
    return this._backendapi;
  }

}
