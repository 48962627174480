<nb-card class="document-add">
    <nb-card-body [class.fileOver]="fileOver && form.get('file').value"
                (dragover)="onDragOver($event)"
                (dragleave)="onDragLeave($event)"
                (drop)="onDrop($event)"
                [class.formError]="formError"
                class="document-add-body">
        <div class="add-file-input" *ngIf="!form.get('file').value">
            <input type="file" id="input-file-id" (change)="upload_file($event)" />
            <label for="input-file-id" id="input-label" class="custom-file-upload"
                [class.noFile]="!form.get('file').value && !fileOver"
                [class.fileOver]="fileOver"
                [class.formError]="formError"
                (dragover)="onDragOver($event)"
                (dragleave)="onDragLeave($event)"
                (drop)="onDrop($event)">
                Ajouter une image
            </label>
        </div>
        <img [src]="this.image"
            class="image"
             *ngIf="this.image && !pdfAuthorized"
            alt="Image à ajouter"/>

        <div *ngIf="form.get('file').value || sizeError || formError || typeError" class="add-file-toolbar">
            <label class="invalid-field" class="custom-file-error" *ngIf="sizeError">
                Votre image ne peut pas dépasser {{maxFileSize}} MB.
            </label>
            <label class="invalid-field" class="custom-file-error" *ngIf="typeError">
                Le document doit être une image ! (png, jpg, svg)
            </label>
            <label class="invalid-field" class="custom-file-error" *ngIf="formError && !form.get('file').value">
                Vous devez ajouter une image.
            </label>
            <input type="file" id="input-file-change" (change)="upload_file($event)" />
            <label *ngIf="form.get('file').value" for="input-file-change" class="custom-file-upload">
                <nb-icon icon="edit-outline"></nb-icon> {{fileName}}
            </label>
        </div>
    </nb-card-body>
</nb-card>
