import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'typeFromBase64'
})
export class TypeFromBase64 implements PipeTransform {

    transform(value: string): string {
        let transformedValue = '';
        if (value) {
            const pointPos = value.indexOf('/');
            const semicolonPos = value.indexOf(';');
            transformedValue = value.slice(pointPos + 1, semicolonPos);
        }
        return transformedValue;
    }

}
