import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'dotIntoSpace'
})
export class DotIntoSpace implements PipeTransform {

    transform(value: string): string {
        if (!value) {
            return '';
        }
        return value.replace('.', ' ');
    }

}
