import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { NbIconLibraries } from '@nebular/theme';
import * as calendarIcon from '../assets/json/custom-icons.json';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { AuthService } from './core/services/access/auth.service';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Constants } from './shared/constant/app.constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isAuthenticated: boolean;
  private _mobile: boolean;
  title = 'frontend-actintech';
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private authService: AuthService,
    private msalBroadcastService: MsalBroadcastService,
    private readonly iconLibraries: NbIconLibraries,
    private readonly _translateService: TranslateService
  ) {
    this.isAuthenticated = this.authService.isUserLoggedIn();
    this._translateService.addLangs(['fr']);
    this._translateService.setDefaultLang('fr');
    this.iconLibraries.registerSvgPack('custom-icons', {
      'calendar-icon': calendarIcon[0],
      'catalog-icon': calendarIcon[1],
      'home-icon': calendarIcon[2],
      'actintech-mobile-icon': calendarIcon[3],
      'actintech-icon': calendarIcon[4],
      'intech-icon': calendarIcon[5],
      'ticket-icon': calendarIcon[6],
      'admin-icon': calendarIcon[7],
      'croissant-icon': calendarIcon[8],
      'actintech-icon-pumpkin': calendarIcon[9],
      'actintech-icon-christmas': calendarIcon[10],
      'actintech-icon-summer': calendarIcon[11],
    });
    const browserLang = this._translateService.getBrowserLang();
    this._translateService.use(browserLang.match(/fr/) ? browserLang : 'fr');
  }

  get mobile(): boolean {
    return this._mobile;
  }

  async ngOnInit(): Promise<void> {
    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe(async (result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(payload.account);
        payload.idTokenClaims as { [key: string]: unknown };
        window.location.reload();
      });

    this.msalBroadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe(async () => {
        if(this.msalService.instance.getAllAccounts().length > 0) {
          const silentRelog = await this.msalService.instance.acquireTokenSilent(Constants.SCOPES);
          this.msalService.instance.setActiveAccount(silentRelog.account);
          this.setIsAuthenticated();}
        });
      
    this._mobile = window.screen.width < environment.breakpoints.extraLarge;
  }

  /* PRIVATE METHODS */
  @HostListener('window:resize', ['$event'])
  private _onResize(event): void {
    this._mobile = event.target.screen.width < environment.breakpoints.extraLarge;
  }

  setIsAuthenticated() {
    this.isAuthenticated = this.msalService.instance.getAllAccounts().length > 0;
  }
}