import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Ticket} from 'src/app/shared/interfaces/ticket';
import {TicketingService} from 'src/app/core/services/ticketing/ticketing.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastersService} from '../../core/services/toasters/toasters.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-ticketing',
  templateUrl: './dashboard-ticketing.component.html',
  styleUrls: ['./dashboard-ticketing.component.scss']
})

export class DashboardTicketingComponent implements OnInit {
  tickets: Ticket[] = [];
  @Output()
  loadingFinished: EventEmitter<void>;
  isSafari: boolean;
  windowVersion: string;

  constructor(
    private readonly _ticketingService: TicketingService,
    private readonly _toastersService: ToastersService
  ) {
    this.loadingFinished = new EventEmitter<void>();
  }

  ngOnInit(): void {
    // safari badly display ticket title
    // need to know if current browser is safari in order to fix it
    const agent = window.navigator.userAgent.toLowerCase();
    if (agent?.includes('safari') && !agent?.includes('chrome')) {
      this.isSafari = true;
    }

    this.windowVersion = (window.screen.width > environment.breakpoints.extraLarge) ? 'browser' : 'mobile';
    this._fetchPopularTickets();
  }
    /* PRIVATE METHODS */
    private _fetchPopularTickets(): void {
      this._ticketingService.fetchPopularTickets()
      .subscribe(
          (tickets: Ticket[]) => {
            this.tickets = tickets;
            $('rank').attr('data-content', 'test');
          },
          (error: HttpErrorResponse) => {
              this._toastersService.showErrorToast(error);
              this.loadingFinished.emit();
          },
          () => this.loadingFinished.emit(),
      );
  }
}
