import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { Router } from '@angular/router';
import { Constants } from 'src/app/shared/constant/app.constants';
import { environment } from 'src/environments/environment';
import { azure } from 'config/azure.environment';
import { filter } from 'rxjs/operators';

@Injectable()
export class AuthService {
  userGroups: string[] | undefined;
  constructor(
    private _msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private http: HttpClient,
    private router: Router,
  ) {}

  async login() {
    this._msalService.loginRedirect(Constants.SCOPES);
    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe(this.onLoginSuccess);
  }

  async onLoginSuccess(result: EventMessage) {
    await this._msalService.instance.handleRedirectPromise();
    const payload = result.payload as AuthenticationResult;
    this._msalService.instance.setActiveAccount(payload.account);
    window.location.assign(this.router.url);
  }

  isUserLoggedIn() {
    return this._msalService.instance.getActiveAccount() != null;
  }

  isAdmin(): boolean {
    return this.getUserGroups().includes(environment.ROLE.ACTINTECH_SUPERADMIN_GROUP);
  }

  isAdminCfl(): boolean {
    return this.getUserGroups().includes(environment.ROLE.ACTINTECH_CFLADMIN_GROUP) || this.getUserGroups().includes(environment.ROLE.ACTINTECH_SUPERADMIN_GROUP);
  }

  isAdminSociety(): boolean {
    return this.getUserGroups().includes(environment.ROLE.ACTINTECH_SOCIETYADMIN_GROUP) || this.getUserGroups().includes(environment.ROLE.ACTINTECH_SUPERADMIN_GROUP);
  }

  async logout() {
    await this._msalService.instance.initialize();
    await this._msalService.instance.logout();

    const accounts = await this._msalService.instance.getAllAccounts();
    if (accounts.length === 0) {
      await this._msalService.instance.loginRedirect();
    }
  }

  async getUserPicture(): Promise<Blob> {
    const activeAccount = this._msalService.instance.getActiveAccount();    
    try {
      const response = await this._msalService.instance.acquireTokenSilent(Constants.SCOPES);

      return this.http.get(azure.microsoftGraph.getUser + activeAccount?.localAccountId + '/photos/240x240/$value', 
      {
        headers: new HttpHeaders({
        'Content-Type': 'image/jpg',
        Authorization: `${environment.bearer}${response.accessToken}`,
        }),
        responseType: 'blob'
      })
      .toPromise()
    } catch (error) {
      return undefined;
    }
  }

  getUserGroups() {
    return (this._msalService.instance.getActiveAccount().idTokenClaims['groups'] ?? []) as string[];
  }

  getDisplayName() {
    return this._msalService.instance.getActiveAccount().name;
  }

  getEmail() {
    return (this._msalService.instance.getActiveAccount().idTokenClaims['email'] ?? '') as string;
  }
}
