import {Component} from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
/*
    Fichier ts pour la page affichant une erreur 404 en cas de tentative d'accès à une page qui n'existe pas
*/
export class NotFoundComponent {
  constructor() {
    // nothing to do here
  }
}
