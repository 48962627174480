export const environment = {
    production: true,
    frontendUrl: '@FRONTEND_URL@',
    backendUrl: '@BACKEND_URL@',
    CLIENT_ID: '@AZURE_CLIENT_ID@',
    TENANT_ID: '@AZURE_TENANT_ID@',
    URL_REDIRECT: '@URL_REDIRECT@',
    ROLE: {
        ACTINTECH_COLLABORATORS_GROUP: '@ACTINTECH_COLLABORATORS_GROUP@',
        ACTINTECH_SUPERADMIN_GROUP: '@ACTINTECH_SUPERADMIN_GROUP@',
        ACTINTECH_SOCIETYADMIN_GROUP: '@ACTINTECH_SOCIETYADMIN_GROUP@',
        ACTINTECH_CFLADMIN_GROUP: '@ACTINTECH_CFLADMIN_GROUP@',
    },
    bearer: 'Bearer ',
    endpoints: {
        // EVENTS
        searchEvents: '/actEvents/search',
        searchEventsToValidate: '/actEvents/toValidate/:searchString',
        myEvents: '/actEvents/myEvents',
        byYearByIds: '/actEvents/byIds',
        actEvents: '/actEvents',
        actEventsCalendar: '/actEvents/calendar',
        mailsOfEvents: '/actEvents/mail/:id',
        actEventsToValidate: '/actEvents/toValidate',
        actEventById: '/actEvents/:id',
        actEventByCategory: '/actEvents/category/:id',
        searchActEventByCategory: '/actEvents/category/:id/search',
        actEventNoValidate: '/actEvents/invalidate/:id',
        actEventValidate: '/actEvents/validate/:id',
        deleteEvent: '/actEvents/:id',
        // USERS
        allUsers: '/actUsers/allActUsers',
        idActUser: '/actUsers/myId/:name',
        filterUsers: '/actUsers/filter',
        me: '/me',
        unactiveUsers: '/actUsers/inactive',
        deactivateInactive: '/actUsers/deactivate-inactive',
        deactivateInactiveUser: '/actUsers/deactivate-inactive/:id',
        // SI-AD
        adUsers: '/si-ad/users',
        adUsersByUserName: '/si-ad/users/:userName',
        searchAdUsers: '/si-ad/users/search',
        // TICKETS
        tickets: '/tickets',
        ticketsHistory: '/tickets/history',
        popularTickets: '/tickets/popular',
        searchTickets: '/tickets/search',
        byPartnership: '/tickets/bypartnership/:id',
        updateTicket: '/tickets/update',
        createTicket: '/tickets/create',
        deleteTicket: '/tickets/delete/:id',
        // PARTNERSHIPS
        partnerships: '/partnerships',
        partnership: '/partnerships/:id',
        updatePartnership: '/partnerships/update/:id',
        createPartnership: '/partnerships/create',
        deletePartnership: '/partnerships/delete/:id',
        partnershipsReduced: '/partnerships/reduced',
        // CONTACT (WITH PARTNERSHIPS)
        updateContact: '/contacts/update/:id',
        createContact: '/contacts/create',
        allContacts: '/contacts',
        // ORDERS
        filterOrders: '/ticket-order/filter',
        updateOrder: '/ticket-order/update/:id',
        createOrder: '/ticket-order/create',
        orderById: '/ticket-order/byId/:id',
        // STOCKS
        ticketStockQuantity: '/ticket-stock/byIdTicket/:idTicket',
        addOrUpdateStock: '/ticket-stock/update',
        allTicketStockQuantities: '/ticket-stock/all',
        // OFFICES
        allOffices: '/ticket-office/all',
        updateOffice: '/ticket-office/update/:id',
        createOffice: '/ticket-office/create',
        deleteOffice: '/ticket-office/delete/:id',
        // OFFICES TRANSACTIONS
        allOfficeTransactions: '/ticket-office-transaction/all',
        createOfficeTransaction: '/ticket-office-transaction/create',
        createDeleteOfficeTransaction: '/ticket-office-transaction/create-delete-transaction',
        // SALES
        allSales: '/sale/all',
        createSale: '/sale/create',
        updateSale: '/sale/update/:id',
        updateHistoricSale: '/sale/update-historic/:id',
        deleteSale: '/sale/delete/:id',
        getTicketsToPay: '/sale/tickets-to-pay',
        updateTicketsToPay: '/sale/update-to-pay/:id',
        mailTicketsToPay: '/sale/mail-to-pay/:id',
        sumSales: '/sale/sum',
        // CATEGORIES
        categories: '/categories',
        postCategory: '/categories/add',
        updateCategory: '/categories/:id',
        categoriesFilter: '/categories/filter',
        searchCategories: '/categories/filter/search',
        searchCategoriesWithoutFilter: '/categories/search',
        categoryById: '/categories/:id',
        idCategoryByName: '/categories/byname/:name',
        // EXPORT
        participantsToExcel: '/export/excel/registration/:id',
        // ORGANIZATION & REGISTRATION
        actEventOrganizers: '/organizations/byEvent/:id',
        allOrganizers: '/organizations/byYear/:type',
        actEventParticipants: '/registration/byEvent/:id',
        searchParticipants: '/registration/byEvent/search/:id',
        actEventUnavailable: '/registration/unavailable/byEvent/:id',
        searchUnavailable: '/registration/unavailable/byEvent/search/:id',
        myRegistration: '/registration/myRegistration/:id',
        alterStatus: '/registration/changeUserStatus',
        registration: '/registration',
        unavailable: '/registration/unavailable',
        updateRegistration: '/registration/:id',
        totalRegistration: '/registration/totalRegistration/:id',
        allUserResponses: '/registration/allUserResponses/:id',
        // MAP
        searchPlace: '/map',
        // DOCUMENTS
        eventThumbnail: '/documents/thumbnail/img/:id',
        eventDocument: '/documents/thumbnail/doc/:id',
        // ROLES
        roles: '/roles/all',
        updatableRoles: 'roles/updatable/:id',
        deleteRole: '/user-roles/delete/:idRole/:idActUser',
        addRole: '/user-roles/add',
        // MAIL
        mail: '/mail',
        // COMMENTARY
        commentaries: '/commentaries',
        commentariesByEvent: '/commentaries/byEvent/:idEvent',
        oneCommentary: '/commentaries/:idCommentary',
        // CROISSANT
        getList: '/croissant-deliveries',
        getMyTurn: '/croissant-deliveries/me',
        subscribe: '/croissant-deliveries',
        subscribeWithSomeone: '/croissant-deliveries/:croissantDeliveryId',
        changeSubscription: '/croissant-deliveries/:actualCroissantDeliveryInfosId/users/change',
        unsubscribe: '/croissant-deliveries/:croissantDeliveryInfosId',
        switchPosition: '/croissant-deliveries/:croissantDeliveryInfosId/users/position',
        forceUnsubscribe: '/croissant-deliveries/:croissantDeliveryInfosId/users/:idUser',
        sendMail: '/croissant-deliveries/mails',
        addVacancies: '/croissant-deliveries/vacancies',
        getVacancies: '/croissant-deliveries/vacancies',
        deleteVacancies: '/croissant-deliveries/vacancies/:vacanciesId',
        getLogs: '/croissant-deliveries/logs'
    },
    breakpoints: {
        extraLarge: 1200,
        large: 992,
        medium: 768,
    },
};
