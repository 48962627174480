import { Injectable } from '@angular/core';
import { SaleResponse } from 'src/app/shared/interfaces/sale-response';
import { SaleUserDTO } from 'src/app/shared/interfaces/sale-user-dto';
import { RoutingBackService } from '../routing-back/routing-back.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SalePage } from 'src/app/shared/interfaces/pages/sale.page';
import { PageRequest } from 'src/app/shared/interfaces/page-request';
import { SaleHistoricUserDTO } from 'src/app/shared/interfaces/sale--historic-user-dto ';

@Injectable({
  providedIn: 'root'
})
export class SaleService {

  constructor(
    private readonly _routingBackService: RoutingBackService,
    private readonly _http: HttpClient
  ) { }

  filterSales(saleFilter: any, page: PageRequest): Observable<SalePage> {
    let httpParams = new HttpParams({ fromObject: saleFilter });
    httpParams = httpParams.append('size', `${page.size}`);
    httpParams = httpParams.append('page', `${page.page}`);
    if (page.sort) {
      page.sort.forEach((value: string) => httpParams = httpParams.append('sort', value));
    }
    return this._http.get<SalePage>(this._routingBackService.backendapi.allSales,
      { params: httpParams });
  }

  /**
   * Sums sale backend call
   * @param saleFilter 
   * @returns sale 
   */
  sumSale(saleFilter: any): Observable<number> {
    let httpParams = new HttpParams({ fromObject: saleFilter });
    return this._http.get<number>(this._routingBackService.backendapi.sumSales,
      { params: httpParams });
  }

  updateSale(saleDTO: SaleUserDTO, idSale: number): Observable<SaleResponse> {
    return this._http.put<SaleResponse>(this._routingBackService.backendapi.updateSale
      .replace(':id', idSale), saleDTO);
  }

  updateHistoricSale(saleDTO: SaleHistoricUserDTO, idSale: number): Observable<SaleResponse> {
    return this._http.put<SaleResponse>(this._routingBackService.backendapi.updateHistoricSale
      .replace(':id', idSale), saleDTO);
  }

  createSale(saleDTO: SaleUserDTO): Observable<SaleResponse> {
    return this._http.post<SaleResponse>(this._routingBackService.backendapi.createSale, saleDTO);
  }

  deleteSale(idSale: number): Observable<SaleResponse> {
    return this._http.delete<SaleResponse>(this._routingBackService.backendapi.deleteSale
      .replace(':id', idSale));
  }

  getTicketsToPay(): Observable<SaleResponse[]> {
    return this._http.get<SaleResponse[]>(this._routingBackService.backendapi.getTicketsToPay);
  }

  updateTicketsToPay(idSale: number): Observable<SaleResponse> {
    return this._http.get<SaleResponse>(this._routingBackService.backendapi.updateTicketsToPay.replace(':id', idSale));
  }

  sendMail(idSale: number): Observable<boolean> {
    return this._http.get<boolean>(this._routingBackService.backendapi.mailTicketsToPay.replace(':id', idSale));
  }

}
