import { Constants } from 'src/app/shared/constant/app.constants';

export const azure = {
  azure: {
    clientId: Constants.AZURE.CLIENT_ID,
    authority:
      'https://login.microsoftonline.com/' + Constants.AZURE.TENANT_ID,
    redirectUri: Constants.AZURE.URL_REDIRECT,
    redirectUriLocal: Constants.AZURE.URL_REDIRECT,
    postLogoutRedirectUriLocal: Constants.AZURE.URL_REDIRECT+'/login',
  },
  microsoftGraph:{
    getUser:'https://graph.microsoft.com/v1.0/users/'
  }
};
