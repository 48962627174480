<nb-card class="dialog-card">
    <nb-card-header class="dialog-header">
        <h3>{{title}}</h3>
    </nb-card-header>
    <nb-card-body>
        <h6>
            {{subtitle}}
        </h6>
        <h6  *ngIf="doubleInputModal" [ngClass]="doubleInputModal ? 'text-center italic':'text-justify'" class="white-space">
            {{content}}
        </h6>
        <div *ngIf="!doubleInputModal" [innerHTML]="content | safeHtml" class="ck-content"></div>
    </nb-card-body>
    <nb-card-footer class="dialog-footer">
        <div class="btn-dialog-group">
            <button nbButton
                    status="primary"
                    size="medium"
                    ghost
                    class="mr-1"
                    (click)="onClose()">
                {{closeBtnTxt}}
            </button>
            <button nbButton
                    *ngIf="doubleInputModal"
                    class="add-event btn-admin"
                    size="medium"
                    shape="round"
                    (click)="onSubmit()">
                Confirmer
            </button>
        </div>
    </nb-card-footer>
</nb-card>
