<div class="more-nav-container">
    <!-- <div class="more-nav-header">
        <img class="img-logout mb-3" src="{{imageUrl}}" alt="" *ngIf="imageUrl; else defaultImage">
        <ng-template #defaultImage>
            <div class="img-logout">
                <span>this.name</span>
            </div>
        </ng-template>
    </div> -->

    <div class="more-nav-items">
        <div class="more-nav-item" [routerLink]="'/croissant'">
            <div class="col-2 more-nav-item-logo">                
                <nb-icon class="nb-icon mb-1" icon="croissant-icon" pack="custom-icons"></nb-icon>
            </div>
            <div class="col-10 more-nav-item-content no-break">
                <h5>Liste des croissants</h5>
                <small class="no-break">Ramener le petit-déjeuner à l'agence</small>
            </div>
        </div>
        <div *ngIf="isAdmin" class="more-nav-item" [routerLink]="'/admin'">
            <div class="col-2 more-nav-item-logo">
                <nb-icon class="nb-icon icon-admin" icon="settings-2-outline"></nb-icon>
            </div>
            <div class="col-10 more-nav-item-content no-break">
                <h5>Administration</h5>
                <small class="no-break">Valider les événements et participants</small>
            </div>
        </div>
        <div class="more-nav-item" (click)="onLogout()">
            <div class="col-2 more-nav-item-logo">
                <nb-icon class="nb-icon icon-logout" icon="log-out-outline"></nb-icon>
            </div>
            <div class="col-10 more-nav-item-content no-break">
                <h5>Déconnexion</h5>
                <small class="no-break">Se déconnecter de la session courante</small>
            </div>
        </div>
    </div>
</div>
