<div class="input-container">
    <button nbButton
            nbSuffix
            size="small"
            type="button"
            class="mr-1"
            (click)="onButtonAddressClicked()">
            Intech
    </button>
    
    <input placeholder="Rechercher un lieu ..."
           maxlength="50"
           nbInput
           fullWidth
           focusInputOnValueChange="false"
           [(ngModel)]="searchPlace"
           [nbAutocomplete]="autocompletePlaces"
           (input)="onInput()"/>
    <div class="spinner-border spinner-border-sm ml-3 input-spinner" role="status" *ngIf="isLoading"></div>
</div>
<nb-autocomplete #autocompletePlaces>
    <nb-option class="autocomplete-option"
               *ngFor="let feature of features"
               [value]="formatAddress(feature.properties)"
               (click)="onOptionSelect(feature)">
        <span class="autocomplete-option-address">
            {{ formatAddress(feature.properties) }}
        </span>
        <span class="autocomplete-option-name">
            {{ feature.properties.name || 'Lieux inconnu'}}
        </span>
    </nb-option>
</nb-autocomplete>
