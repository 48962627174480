import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {NotFoundComponent} from './shared/components/not-found/not-found.component';
import {NavbarMobileComponent} from './shared/components/navbar/navbar-mobile/navbar-mobile.component';
import {AdminGuard} from './core/guards/admin/admin.guard';
import {MobileMenuGuard} from './core/guards/mobile-menu/mobile-menu.guard';
import {MsalGuard} from '@azure/msal-angular';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'catalog',
    loadChildren: () => import('./catalog/catalog.module').then((m) => m.CatalogModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'croissant',
    loadChildren: () => import('./croissant/croissant.module').then((m) => m.CroissantModule),
  },
  {
    path: 'events',
    loadChildren: () => import('./event/event.module').then((m) => m.EventModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [MsalGuard, AdminGuard],
  },
  {
    path: 'ticketing',
    loadChildren: () => import('./ticketing/ticketing.module').then((m) => m.TicketingModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'more-nav',
    component: NavbarMobileComponent,
    canActivate: [MsalGuard, MobileMenuGuard]
  },
  {
    path: '**', component: NotFoundComponent,
    canActivate: [MsalGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
