import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
} from '@angular/common/http';

// Rxjs
import { Observable, from } from 'rxjs';

// Constants

import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/core/services/access/auth.service';
import { Constants } from '../constant/app.constants';
import { environment } from 'src/environments/environment';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private msalService: MsalService, private authservice: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(req, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.startsWith(environment.backendUrl)) {
      const tokenExpirationTime = this.msalService.instance.getActiveAccount()?.idTokenClaims?.exp;
      if (
        tokenExpirationTime &&
        tokenExpirationTime <= Math.floor(new Date().getTime() / 1000) //If the token is expired
      ) {
        await this.authservice.login();
      }
      const token = (await this.msalService.instance.acquireTokenSilent(Constants.SCOPES)).idToken;
      const newHeaders = {
        headers: new HttpHeaders({
          Authorization: `${environment.bearer}${token}`,
        }),
      };
      return next.handle(req.clone(newHeaders)).toPromise();
    } 
    return next.handle(req).toPromise();
  }
}
