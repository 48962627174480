import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import {RoutingBackService} from '../routing-back/routing-back.service';
import {Observable} from 'rxjs';
import {Ticket} from '../../../shared/interfaces/ticket';

@Injectable({
  providedIn: 'root'
})
export class TicketingService {

  constructor(
      private readonly _http: HttpClient,
      private readonly _routingBackService: RoutingBackService) { }

  fetchAllTickets(image: boolean): Observable<Ticket[]> {
    if(image){
      // Loading for the public ticket page
      return this._http.get<Ticket[]>(this._routingBackService.backendapi.tickets);
    } else {
      // Loading for the history so we don't need the image
      return this._http.get<Ticket[]>(this._routingBackService.backendapi.ticketsHistory);
    }
  }

  fetchPopularTickets(): Observable<Ticket[]> {
    return this._http.get<Ticket[]>(this._routingBackService.backendapi.popularTickets);
  }

  searchTicket(searchString: string): Observable<Ticket[]> {
    const httpParams = new HttpParams()
        .set('searchString', searchString);
    return this._http.get<Ticket[]>(this._routingBackService.backendapi.searchTickets, { params: httpParams });
  }

  fetchByPartnership(idPartnership: number): Observable<Ticket[]> {
    return this._http.get<Ticket[]>(this._routingBackService.backendapi.byPartnership.replace(':id', idPartnership));
  }

  updateTicket(ticketList: Ticket[]): Observable<Ticket[]> {
    return this._http.put<Ticket[]>(this._routingBackService.backendapi.updateTicket, ticketList);
  }

  createTicket(ticketList: Ticket[]): Observable<Ticket[]> {
    return this._http.post<Ticket[]>(this._routingBackService.backendapi.createTicket, ticketList, { headers: new HttpHeaders() });
  }

  deleteTicket(idTicket: number): Observable<Ticket> {
    return this._http.delete<Ticket>(this._routingBackService.backendapi.deleteTicket.replace(':id', idTicket));
  }
}
