
<div *ngIf="windowVersion==='mobile'" class="d-flex justify-content-center mt-4">
    <button *ngIf="ticketsToPay.length > 0" nbButton shape="round" class="add-event card-shadow width-auto"
        status="danger"
        (click)="onShowTicketsToPay()">
        <span>Rappel de paiement</span>
    </button>
</div>


<div class="d-flex justify-content-md-between align-items-md-center page-header" *ngIf="windowVersion==='browser'">
    <div class="sub-title">
        CALENDRIER DES ÉVÉNEMENTS
    </div>
    <div>
        <button *ngIf="ticketsToPay.length > 0" nbButton shape="round" class="add-event card-shadow width-auto mr-5"
                status="danger"
                (click)="onShowTicketsToPay()">
                <span>Rappel de paiement</span>
        </button>

        <button nbButton shape="round" class="add-event btn-admin"
                (click)="onCreateEventClick()">
            <span>PROPOSER UN ÉVÉNEMENT</span>
        </button>
    </div>
</div>

<div class="sub-title mt-5" *ngIf="windowVersion!=='browser'">
    LE CALENDRIER
</div>

<div class="calendar-superior-container d-flex flex-grow-1 justify-content-center">
    <div class="calendar-container">
        <full-calendar #calendar id="calendar" [options]="calendarOptions" 
        style="
            border-radius: 10px;
            padding-top: 5px;
            margin-bottom: 10px">
        </full-calendar>
        <nb-checkbox class="checkbox-holiday checkbox-past hidden-mobile mt-3" 
            [(checked)]="toggleHolidaysEvent" (checkedChange)="disableHolidays($event)">
            Cacher les vacances scolaires
        </nb-checkbox>
    </div>
</div>
