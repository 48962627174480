<nb-card class="dialog-card">
    <nb-card-header class="dialog-header">
        <h3>{{title}}</h3>
    </nb-card-header>
    <nb-card-body>
        <h6>
            {{subtitle}}
        </h6>
        <h6 class="italic">
            {{content}}
        </h6>
        <form [formGroup]="form">
            <div class="form-group">
                <textarea
                        id="userInput"
                        name="userInput"
                        class="form-control"
                        formControlName="userInput"
                        [ngClass]="{'is-invalid': form.get('userInput').dirty && form.get('userInput').invalid}">
                </textarea>
                <div class="invalid-field"
                     *ngIf="form.get('userInput').dirty && form.get('userInput').invalid">
                    La taille autorisée est de 250 caractères maximum.
                </div>
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer class="dialog-footer">
        <div class="btn-dialog-group">
            <button nbButton
                    status="primary"
                    size="medium"
                    ghost
                    class="mr-1"
                    (click)="onClose()">
                Annuler
            </button>
            <button nbButton
                    class="add-event btn-admin"
                    size="medium"
                    shape="round"
                    [disabled]="getFormControl().length < 3"
                    (click)="onSubmit()">
                Confirmer
            </button>
        </div>
    </nb-card-footer>
</nb-card>
