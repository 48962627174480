import {Injectable} from '@angular/core';
import {RoutingBackService} from '../routing-back/routing-back.service';
import {Observable} from 'rxjs';
import {Event} from '../../../shared/interfaces/event';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {EventDTO} from '../../../shared/interfaces/eventDTO';
import {ActEventPage} from '../../../shared/interfaces/pages/act-event.page';
import {PageRequest} from '../../../shared/interfaces/page-request';

/*
    Contient toutes les méthodes relatives à un événement
*/
@Injectable({
  providedIn: 'root'
})
export class EventService {
  typeJson = 'application/json';

  constructor(
      private readonly _http: HttpClient,
      private readonly _routingBackService: RoutingBackService) { }

  fetchAll(filters: any, page: PageRequest): Observable<ActEventPage> {
    let httpParams = new HttpParams({fromObject: filters});
    httpParams = httpParams.append('size', `${page.size}`);
    httpParams = httpParams.append('page', `${page.page}`);
    if (page.sort) {
      page.sort.forEach((value: string) => httpParams = httpParams.append('sort', value));
    }
    return this._http.get<ActEventPage>(this._routingBackService.backendapi.actEvents, { params: httpParams });
  }

  fetchAllWithoutPaging(filters: any): Observable<ActEventPage> {
    const httpParams = new HttpParams({fromObject: filters});
    return this._http.get<ActEventPage>(this._routingBackService.backendapi.actEventsCalendar, { params: httpParams });
  }

  fetchOneById(id: number): Observable<Event> {
    return this._http.get<Event>(this._routingBackService.backendapi.actEventById.replace(':id', id));
  }

  fetchByCategory(id: number): Observable<ActEventPage> {
    return this._http.get<ActEventPage>(this._routingBackService.backendapi.actEventByCategory.replace(':id', id));
  }

  searchEventByCategory(id: number, searchString: string): Observable<ActEventPage> {
    const httpParams = new HttpParams()
        .set('searchString', searchString);
    return this._http.get<ActEventPage>(this._routingBackService.backendapi
        .searchActEventByCategory
        .replace(':id', id), { params: httpParams });
  }

  fetchMyEvents(filters: any): Observable<Event[]> {
    const httpParams = new HttpParams({fromObject: filters});
    return this._http.get<Event[]>(this._routingBackService.backendapi.myEvents, { params: httpParams });
  }

  fetchByIds(ids: number[], filters: any): Observable<Event[]> {
    let httpParams = new HttpParams({ fromObject: filters});
    httpParams = httpParams.append('ids', ids.toString());
    return this._http.get<Event[]>(this._routingBackService.backendapi.byYearByIds, { params: httpParams });
  }

  fetchAllEventsToValidate(): Observable<Event[]> {
    return this._http.get<Event[]>(this._routingBackService.backendapi.actEventsToValidate);
  }

  searchEvent(searchString: string, filters: any, page: PageRequest): Observable<ActEventPage> {
    let httpParams = new HttpParams({ fromObject: filters });
    httpParams = httpParams.append('searchString', searchString);
    httpParams = httpParams.append('size', `${page.size}`);
    httpParams = httpParams.append('page', `${page.page}`);
    return this._http.get<ActEventPage>(this._routingBackService.backendapi.searchEvents, { params: httpParams });
  }

  searchEventToValidate(searchString: string): Observable<Event[]> {
    return this._http.get<Event[]>(this._routingBackService.backendapi.searchEventsToValidate.replace(':searchString', searchString));
  }

  fetchMailsFromEvent(idEvent: number, eventMailFilter: any): Observable<string[]> {
    const httpParams = new HttpParams({ fromObject: eventMailFilter });
    return this._http.get<string[]>(this._routingBackService.backendapi.mailsOfEvents.replace(':id', idEvent), { params: httpParams });
  }

  postEvent(event: EventDTO, file: File): Observable<EventDTO> {
    const formData = new FormData();
    formData.append('event', new Blob([JSON.stringify(event)], {type: this.typeJson}));
    formData.append('file', file, file.name);
    return this._http.post<EventDTO>(this._routingBackService.backendapi.actEvents, formData);
  }

  updateEvent(id: number, event: EventDTO, file: File): Observable<EventDTO> {
    const formData = new FormData();
    formData.append('event', new Blob([JSON.stringify(event)], {type: this.typeJson}));
    formData.append('file', file, file.name);
    return this._http.put<EventDTO>(this._routingBackService.backendapi.actEventById.replace(':id', id), formData);
  }

  putEventDate(event: Event, id: number): Observable<Event> {
    const putUrl = this._routingBackService.backendapi.actEvents + id;
    return this._http.put(putUrl, event,
      { headers: new HttpHeaders(Object.assign({ 'Content-Type': this.typeJson })) });
  }

  validateAnEvent(idEvent: number): Observable<Event> {
    return this._http.put(this._routingBackService.backendapi.actEventValidate.replace(':id', idEvent), {});
  }

  doNotValidateAnEvent(idEvent: number): Observable<Event> {
    return this._http.put(this._routingBackService.backendapi.actEventNoValidate.replace(':id', idEvent), {});
  }

  deleteEvent(idEvent: number, message: string): Observable<boolean> {
    const httpParams = new HttpParams({ fromObject: {organizerMessage: encodeURIComponent(message)} });
    return this._http.delete<boolean>(this._routingBackService.backendapi.deleteEvent.replace(':id', idEvent), {params: httpParams});
  }
}
