import {Component, EventEmitter, HostListener, OnInit} from '@angular/core';
import {Event} from '../shared/interfaces/event';
import {EventService} from '../core/services/event/event.service';
import {DatePipe} from '@angular/common';
import {environment} from '../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastersService} from '../core/services/toasters/toasters.service';
import {ActEventPage} from '../shared/interfaces/pages/act-event.page';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
/*
    Gestion de l'affichage des éléments dans le dashboard
*/
export class DashboardComponent implements OnInit {
  loadingFinished: EventEmitter<void>;
  events: Event[] = [];
  isLoadingEvents = false;
  isLoadingTickets = false;
  isLoadingMyEvents = false;
  windowVersion: string;

  constructor(
      private readonly _eventService: EventService,
      private readonly _datePipe: DatePipe,
      private readonly _toastersService: ToastersService
  ) {
    this.loadingFinished = new EventEmitter<void>();
  }

  ngOnInit(): void {
    this.isLoadingMyEvents = true;
    this.isLoadingTickets = true;
    this.windowVersion = (window.screen.width > environment.breakpoints.extraLarge) ? 'browser' : 'mobile';
    this._fetchEvents();
  }

  /* PRIVATE METHODS */

  @HostListener('window:resize', ['$event'])
  private _onResize(event): void {
    if (this.windowVersion === 'browser' && event.target.screen.width < environment.breakpoints.extraLarge) { // Bootstrap media query
      this.windowVersion = 'mobile';
    } else if (this.windowVersion === 'mobile' && event.target.screen.width > environment.breakpoints.extraLarge) { // Bootstrap media query
      this.windowVersion = 'browser';
    }
  }

  private _fetchEvents(): void {
    this.isLoadingEvents = true;
    this._eventService.fetchAllWithoutPaging({
      minDate: this._datePipe.transform(new Date(), 'dd-MM-yyyy'),
      cfl: true,
      societe: true,
      showPast: true,
      holiday: true,
      deleted: false,
      future: false
    }).subscribe(
        (events: ActEventPage) => this.events = events.content,
        (error: HttpErrorResponse) => this._toastersService.showErrorToast(error),
        () => {
          this.isLoadingEvents = false;
        },
    );
  }

  /* INIT METHODS */

  /* ON USER ACTION METHODS */

  /* OTHER METHODS */

  public isComponentLoaded(): boolean {
    return ((!this.isLoadingTickets || this.isMobile()) && !this.isLoadingMyEvents && !this.isLoadingEvents);
  }

  public isMobile(): boolean {
    return this.windowVersion === 'mobile';
  }

  public onLoadingFinishedMyEvents(): void {
    this.isLoadingMyEvents = false;
  }

  public onLoadingFinishedTickets(): void {
    this.isLoadingTickets = false;
  }

}
