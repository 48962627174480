import {Injectable} from '@angular/core';
import {RoutingBackService} from '../routing-back/routing-back.service';
import {Observable} from 'rxjs';
import {Category} from '../../../shared/interfaces/category';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {PageRequest} from '../../../shared/interfaces/page-request';

/*
    Contient les méthodes relatives à la catégorie d'un événement
*/
@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
      private readonly _http: HttpClient,
      private readonly _routingBackService: RoutingBackService
  ) { }


  fetchOneById(id: number): Observable<Category> {
    return this._http.get<Category>(this._routingBackService.backendapi.categoryById.replace(':id', id));
  }

  fetchAll(): Observable<Category[]> {
    return this._http.get<Category[]>(this._routingBackService.backendapi.categories);
  }

  fetchAllWithFilter(filter: any, page: PageRequest): Observable<Category[]> {
    let httpParams = new HttpParams({fromObject: filter});
    if (page.sort) {
      page.sort.forEach((value: string) => httpParams = httpParams.append('sort', value));
    }
    return this._http.get<Category[]>(this._routingBackService.backendapi.categoriesFilter, { params: httpParams });
  }

  searchCategory(filter: any, searchString: string, page: PageRequest): Observable<Category[]> {
    let httpParams = new HttpParams({fromObject: filter});
    httpParams = httpParams.append('searchString', searchString);
    if (page.sort) {
      page.sort.forEach((value: string) => httpParams = httpParams.append('sort', value));
    }
    return this._http.get<Category[]>(this._routingBackService.backendapi.searchCategories,
        { params: httpParams });
  }

  searchCategoryWithoutFilter(searchString: string): Observable<Category[]> {
    const httpParams = new HttpParams().set('searchString', searchString);
    return this._http.get<Category[]>(this._routingBackService.backendapi.searchCategoriesWithoutFilter,
        { params: httpParams });
  }

  fetchIdByName(name: string): Observable<Category> {
    return this._http.get<Category>(this._routingBackService.backendapi.idCategoryByName.replace(':name', name));
  }

  addCategory(category: Category): void {
    this.postCategory(category).subscribe();
  }

  postCategory(category: Category): Observable<Category> {
    return this._http.post<Category>(this._routingBackService.backendapi.postCategory, category,
    { headers: new HttpHeaders(Object.assign({ 'Content-Type': 'application/json' }, {})) });
  }

  updateCategory(category: Category): Observable<Category> {
    return this._http.put<Category>(this._routingBackService.backendapi.updateCategory.replace(':id', category.idCategory), category,
    { headers: new HttpHeaders(Object.assign({ 'Content-Type': 'application/json' }, {})) });
  }

  deleteCategory(id: number): Observable<void> {
    return this._http.delete<void>(this._routingBackService.backendapi.updateCategory.replace(':id', id));
  }

  getCategoryId(categories: Category[], eventCategory: string): number {
    for (const category of categories) {
      if (category.name === eventCategory) {
        return category.idCategory;
      }
    }
    return 1;
  }

}
